<template>
  <section class="livelo-tab">
    <div v-if="tempContract.status != 'canceled'">
      <aRow v-if="liveloTransactionId == undefined" :gutter="20">
        <aCol
          v-if="
            (contractedServices.length > 0 &&
              tempContract.package_type == 'Serviços') ||
            'Reserva Online'
          "
          :span="24"
          class="mb-20"
        >
          <aAlert
            v-if="showSubtractProfit"
            class="f12"
            type="info"
            message="Selecione os produtos participantes do programa:"
            banner
          />
        </aCol>

        <aCol
          v-if="
            (contractedServices.length > 0 &&
              tempContract.package_type == 'Serviços') ||
            'Reserva Online'
          "
          :span="24"
        >
          <a-form-item v-if="hotelRows.length > 0" class="travel-check-boxes">
            <label :class="'label'">Hotéis </label>
            <a-checkbox-group
              v-decorator="[
                'livelo_participating_products_hotels',
                {
                  rules: [
                    {
                      required: false,
                      message: 'Obrigatório',
                    },
                  ],
                },
              ]"
              style="width: 100%"
              @change="resetPointsAndRule"
            >
              <a-checkbox
                v-for="(product, index) in hotelRows"
                :key="index"
                :value="product.id"
                :disabled="blockFields"
              >
                <span
                  class="dotted-phrase"
                  style="
                    max-width: 320px;
                    display: inline-block;
                    margin-bottom: -6px;
                  "
                  >{{ tempContract[`hotel_${product.id}_name`] }}
                </span>
                <span class="ml-10 f12 cprimary"
                  >({{
                    formatPricePtBr(
                      tempContract[`hotel_${product.id}_total_price`]
                    )
                  }})</span
                >
              </a-checkbox>
            </a-checkbox-group>
          </a-form-item>

          <a-form-item
            v-if="flightSectionsRows.length > 0"
            class="travel-check-boxes"
          >
            <label :class="'label'">Aéreos </label>
            <a-checkbox-group
              v-decorator="[
                'livelo_participating_products_flights',
                {
                  rules: [
                    {
                      required: false,
                      message: 'Obrigatório',
                    },
                  ],
                },
              ]"
              style="width: 100%"
              @change="resetPointsAndRule"
            >
              <a-checkbox
                v-for="(product, index) in flightSectionsRows"
                :key="index"
                :value="product.id"
                :disabled="blockFields"
              >
                <span class="dotted-phrase upper">
                  Aéreo
                  {{ tempContract[`flight_${product.id}_type`] }}
                </span>
                <span class="ml-10 f12 cprimary"
                  >({{
                    formatPricePtBr(
                      tempContract[`flight_${product.id}_total_price`]
                    )
                  }})
                </span>
              </a-checkbox>
            </a-checkbox-group>
          </a-form-item>

          <a-form-item v-if="serviceRows.length > 0" class="travel-check-boxes">
            <label :class="'label'">Serviços </label>
            <a-checkbox-group
              v-decorator="[
                'livelo_participating_products_services',
                {
                  rules: [
                    {
                      required: false,
                      message: 'Obrigatório',
                    },
                  ],
                },
              ]"
              style="width: 100%"
              @change="resetPointsAndRule"
            >
              <a-checkbox
                v-for="(product, index) in serviceRows"
                :key="index"
                :value="product.id"
                :disabled="blockFields"
              >
                <span
                  class="dotted-phrase upper"
                  style="
                    max-width: 320px;
                    display: inline-block;
                    margin-bottom: -6px;
                  "
                  >{{ tempContract[`service_${product.id}_name`] }}</span
                >
                <span class="ml-10 f12 cprimary"
                  >({{
                    formatPricePtBr(
                      tempContract[`service_${product.id}_total_price`]
                    )
                  }})</span
                >
              </a-checkbox>
            </a-checkbox-group>
          </a-form-item>
        </aCol>

        <span v-if="calcTotalParticipatingProgramsValue() > 0">
          <aCol :span="24" class="mb-30">
            <a-alert
              class="f12"
              type="info"
              message="Informe a regra de pontuação Livelo abaixo."
              banner
            />
          </aCol>
          <aCol :span="8">
            <a-form-item class="travel-input-outer">
              <label :class="'filled'">CPF do cliente</label>
              <a-input
                class="travel-input readonly"
                placeholder="Ex: 48888063650"
                type="number"
                v-decorator="[
                  `livelo_customer_id`,
                  {
                    initialValue: this.tempContract.livelo_customer_id,
                  },
                ]"
              >
                <a-icon slot="prefix" type="field-svg" />
              </a-input>
            </a-form-item>
          </aCol>
          <aCol :span="8">
            <a-form-item class="travel-input-outer">
              <label :class="'filled'">Regra dos pontos</label>

              <a-select
                class="travel-input select"
                placeholder="Selecione"
                @change="onChangeLiveloPointsRule"
                v-decorator="[
                  `livelo_points_rule`,
                  {
                    rules: [
                      {
                        required: true,
                        message: 'Obrigatório',
                      },
                    ],
                  },
                ]"
                :disabled="blockFields"
              >
                <a-select-option
                  v-for="(item, index) of liveloRange"
                  :key="index"
                  :value="item.rule"
                >
                  R$ 1 = {{ item.rule }} pts Livelo
                </a-select-option>
              </a-select>
            </a-form-item>
          </aCol>
          <aCol :span="8">
            <a-form-item class="travel-input-outer" style="display: none">
              <label :class="'filled'">Quantidade pontos Livelo</label>
              <a-input
                class="travel-input"
                placeholder="Ex: 400"
                type="number"
                v-decorator="[`livelo_amount`]"
              >
                <a-icon slot="prefix" type="field-svg" />
              </a-input>
            </a-form-item>
          </aCol>

          <aCol :span="8">
            <a-form-item class="travel-input-outer">
              <label :class="'filled'">Pontos Livelo a receber</label>
              <a-input
                class="travel-input readonly"
                placeholder="Ex: 400"
                type="number"
                v-decorator="[`livelo_amount_to_receive`]"
              >
                <a-icon slot="prefix" type="field-svg" />
              </a-input>
            </a-form-item>
          </aCol>

          <aCol :span="8">
            <a-form-item class="travel-input">
              <label :class="'filled'">Data da transação da Livelo</label>
              <a-date-picker
                placeholder="Selecione"
                format="DD/MM/YYYY HH:mm:ss"
                valueFormat="YYYY-MM-DDTHH:mm:ss"
                :showToday="false"
                :allowClear="true"
                v-mask="'##/##/####'"
                :disabled="blockFields"
                v-decorator="[
                  'livelo_transaction_date',
                  {
                    rules: [
                      {
                        required: false,
                        message: 'Obrigatório',
                      },
                    ],
                  },
                ]"
              >
                <a-icon slot="prefix" type="field-svg" />
              </a-date-picker>
            </a-form-item>
          </aCol>

          <aCol :span="8">
            <a-form-item class="travel-input-outer">
              <label :class="'filled'">Status do envio</label>

              <a-select
                class="travel-input select readonly"
                placeholder="Selecione"
                v-decorator="[
                  `livelo_points_is_sent`,
                  {
                    rules: [
                      {
                        required: false,
                        message: 'Obrigatório',
                      },
                    ],
                  },
                ]"
              >
                <a-select-option
                  v-for="(item, index) of [
                    {
                      label: 'Pendente',
                    },
                    {
                      label: 'Finalizado',
                    },
                  ]"
                  :key="index"
                  :value="item.rule"
                >
                  {{ item.label }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </aCol>

          <aCol :span="8">
            <a-form-item class="travel-input-outer">
              <label :class="'filled'">ID gerado na livelo</label>
              <a-input
                class="travel-input readonly"
                placeholder="Gerado automaticamente"
                v-decorator="[`livelo_transaction_id`]"
              >
                <a-icon slot="prefix" type="field-svg" />
              </a-input>
            </a-form-item>
          </aCol>

          <aCol v-if="tempContract.livelo_amount" :span="24">
            <div class="resume">
              <h2>DADOS LIVELO</h2>
              <div v-if="productId">
                ID Produto (productId): <b> {{ productId }} </b>
              </div>

              ID transação Haya : <b> {{ transactionId }} </b> <br />
              Data transação:
              <span v-if="tempContract.livelo_transaction_date">
                {{
                  tempContract.livelo_transaction_date
                    | formatLiveloTransactionDate
                }}
              </span>

              <span v-else>
                Selecione acima no campo "Data da transação da Livelo"</span
              >

              <br />
              CPF do benficiário:
              <b> {{ tempContract.the_customer.cpf }} </b> <br />
              Valor total dos produtos participantes:
              <b
                >{{
                  formatPricePtBr(calcTotalParticipatingProgramsValue())
                }} </b
              ><br />
              <div v-if="tempContract.livelo_amount">
                Regra de pontuação:
                <b>
                  R$ 1 = {{ tempContract.livelo_points_rule }} pts Livelo ({{
                    getTheRule(tempContract.livelo_points_rule)
                  }})</b
                >
              </div>

              <div v-if="tempContract.livelo_amount_to_receive">
                Pontos Livelo:
                <b>{{ tempContract.livelo_amount_to_receive }}</b>
              </div>
              <div v-if="pointsTotalFee != 0">
                Custo:
                <font class="red">
                  {{ formatPricePtBr(this.pointsTotalFee) }}</font
                >
              </div>
            </div>
          </aCol>

          <aCol class="mb-20" :span="24">
            <a-alert
              v-if="productId"
              class="f12 mb-20"
              type="warning"
              :message="`Atenção! Ao enviar os pontos certifique-se que a data de transação está dentro do período válido da campanha (${productId}).`"
              banner
            />

            <a-alert
              v-if="disableLiveloUntilLastCheckout()"
              class="f12 mb-20"
              type="warning"
              message="O envio dos pontos para Livelo ficará disponível somente após a última data de checkout do contrato."
              banner
            />

            <a-button
              v-show="
                tempContract.livelo_points_rule &&
                tempContract.livelo_amount &&
                tempContract.livelo_customer_id
              "
              type="primary"
              :loading="loadingSendPointsToLivelo"
              :disabled="disableLiveloUntilLastCheckout()"
              @click="getLiveloToken()"
            >
              Enviar pontos para Livelo
            </a-button>

            <a-checkbox
              v-model="subtractLiveloFeesInProfit"
              style="
                font-size: 13px;
                font-weight: 500;
                position: relative;
                margin-left: 30px;
              "
              @change="onSelectLiveloFeeInProfit"
              v-if="showSubtractProfit"
            >
              Subtrair da lucratividade
            </a-checkbox>
          </aCol>
        </span>
      </aRow>

      <aRow v-if="liveloTransactionId != undefined" :gutter="20">
        <aCol :span="24">
          <div class="resume">
            <h2>DADOS LIVELO</h2>

            <div v-if="productId">
              ID Produto (productId): <b> {{ productId }} </b>
            </div>
            ID transação Haya: <b> {{ transactionId }} </b> <br />
            CPF do benficiário:
            <b> {{ tempContract.the_customer.cpf }} </b> <br />
            Valor total dos produtos participantes:
            <b>{{ formatPricePtBr(calcTotalParticipatingProgramsValue()) }} </b
            ><br />
            Regra de pontuação:
            <b>
              R$ 1 = {{ tempContract.livelo_points_rule }} pts Livelo ({{
                getTheRule(tempContract.livelo_points_rule)
              }})</b
            >
            <br />
            Pontos Livelo:
            <b>{{ tempContract.livelo_amount_to_receive }}</b
            ><br />
            Data de geração da transação:
            <b>
              {{
                tempContract.livelo_transaction_generation_date
                  | formatMultiDateTime
              }}
            </b>
            <br /><br />

            <div class="f14 cprimary">
              <b
                >ID Transação Livelo:
                {{ tempContract.livelo_transaction_id }}</b
              >
            </div>
          </div>
        </aCol>
      </aRow>
    </div>
    <div v-else>
      <a-alert
        class="f12"
        type="info"
        message="Este contrato foi cancelado, não será possível o envio de pontos Livelo."
        banner
      />
    </div>
  </section>
</template>

<script>
import qs from "qs";
import axios from "axios";
import { parse } from "vue-currency-input";
import { addSeconds } from "date-fns";

import formatThings from "@/mixins/general/formatThings";

export default {
  name: "LiveloTab",
  props: {
    total: Number,
    contract: Object,
    tempContract: Object,
    form: Object,
    showSubtractProfit: Boolean,
    blockFields: Boolean,
  },
  mixins: [formatThings],
  data() {
    return {
      productId: "",
      contractedServices: [],
      hotelRows: [],
      serviceRows: [],
      flightSectionsRows: [],
      transactionId: `TSS-${new Date().getFullYear()}${this.monthWithZero(
        new Date().getMonth() + 1
      )}${this.tempContract.id}`,
      liveloTransactionId: undefined,
      theLiveloToken: "",
      loadingSendPointsToLivelo: false,
      liveloRange: [],
      subtractLiveloFeesInProfit: false,
      pointsTotalFee: 0,
    };
  },
  mounted() {
    this.getLiveloRules();

    if (this.tempContract.livelo_customer_id == undefined)
      this.removeLettersAndSpecialChars(this.tempContract.the_customer.cpf);

    if (this.tempContract.livelo_points_is_sent == undefined) {
      this.tempContract.livelo_points_is_sent = "Pendente";
    } else {
      if (
        this.tempContract.livelo_points_is_sent != 1 ||
        this.tempContract.livelo_points_is_sent != true
      )
        this.tempContract.livelo_points_is_sent = "Pendente";
    }

    this.tempContract.livelo_subtract_fees_in_profit =
      this.tempContract.livelo_subtract_fees_in_profit == 1 ? true : false;

    if (this.tempContract.livelo_subtract_fees_in_profit != undefined) {
      this.subtractLiveloFeesInProfit =
        this.tempContract.livelo_subtract_fees_in_profit;
    }

    this.liveloTransactionId = this.tempContract.livelo_transaction_id;
    this.pointsTotalFee = this.tempContract.livelo_points_total_fee
      ? this.tempContract.livelo_points_total_fee
      : 0;

    this.contractedServices = this.tempContract.contracted_services
      ? JSON.parse(this.tempContract.contracted_services)
      : [];

    this.hotelRows = this.tempContract["hotel_rows"]
      ? JSON.parse(this.tempContract["hotel_rows"])
      : [];

    this.flightSectionsRows = this.tempContract["flight_sections_rows"]
      ? JSON.parse(this.tempContract["flight_sections_rows"])
      : [];

    this.serviceRows = this.tempContract["service_rows"]
      ? JSON.parse(this.tempContract["service_rows"])
      : [];

    this.form.setFieldsValue({
      livelo_customer_id: this.removeLettersAndSpecialChars(
        this.tempContract.the_customer.cpf
      ),
      livelo_amount: this.tempContract.livelo_amount,
      livelo_amount_to_receive: this.tempContract.livelo_amount_to_receive,
      livelo_points_rule: this.tempContract.livelo_points_rule
        ? parseInt(this.tempContract.livelo_points_rule)
        : undefined,
      livelo_points_is_sent: this.tempContract["livelo_points_is_sent"],
      livelo_transaction_id: this.tempContract["livelo_transaction_id"],
      [`livelo_transaction_date`]: this.tempContract["livelo_transaction_date"],
    });

    setTimeout(() => {
      this.form.setFieldsValue({
        livelo_participating_products_hotels: this.tempContract
          .livelo_participating_products_hotels
          ? JSON.parse(this.tempContract.livelo_participating_products_hotels)
          : [],
        livelo_participating_products_flights: this.tempContract
          .livelo_participating_products_flights
          ? JSON.parse(this.tempContract.livelo_participating_products_flights)
          : [],
        livelo_participating_products_services: this.tempContract
          .livelo_participating_products_services
          ? JSON.parse(this.tempContract.livelo_participating_products_services)
          : [],
      });

      if (this.tempContract.livelo_haya_product_id != undefined)
        this.productId = this.tempContract.livelo_haya_product_id;
    }, 300);
  },
  methods: {
    resetPointsAndRule() {
      this.form.setFieldsValue({
        livelo_amount: undefined,
        livelo_amount_to_receive: undefined,
        livelo_points_rule: undefined,
      });

      this.tempContract.livelo_amount_to_receive = "";
      this.productId = "";
    },
    calcTotalParticipatingProgramsValue() {
      let totalValue = 0;

      if (
        this.tempContract.package_type == "Serviços" ||
        this.tempContract.package_type == "Reserva Online"
      ) {
        let participatingHotelsArr = this.tempContract
            .livelo_participating_products_hotels
            ? JSON.parse(this.tempContract.livelo_participating_products_hotels)
            : [],
          participatingFlightsArr = this.tempContract
            .livelo_participating_products_flights
            ? JSON.parse(
                this.tempContract.livelo_participating_products_flights
              )
            : [],
          participatingServicesArr = this.tempContract
            .livelo_participating_products_services
            ? JSON.parse(
                this.tempContract.livelo_participating_products_services
              )
            : [];

        if (participatingHotelsArr.length > 0) {
          participatingHotelsArr.forEach((id) => {
            totalValue += parse(this.tempContract[`hotel_${id}_total_price`]);
          });
        }

        if (participatingFlightsArr.length > 0) {
          participatingFlightsArr.forEach((id) => {
            totalValue += parse(this.tempContract[`flight_${id}_total_price`]);
          });
        }

        if (participatingServicesArr.length > 0) {
          participatingServicesArr.forEach((id) => {
            totalValue += parse(this.tempContract[`service_${id}_total_price`]);
          });
        }
      } else {
        totalValue = this.total;
      }

      return totalValue;
    },
    onSelectLiveloFeeInProfit(val) {
      val;
      this.tempContract.livelo_subtract_fees_in_profit =
        this.subtractLiveloFeesInProfit;
      this.$emit("tempContractUpdate", this.tempContract);
    },
    disableLiveloUntilLastCheckout() {
      let disableButton = true,
        today = new Date().getTime(),
        lastCheckout = this.tempContract.contract_last_checkout
          ? new Date(this.tempContract.contract_last_checkout).getTime()
          : undefined;

      if (today > lastCheckout)
        if (
          this.tempContract.livelo_points_rule != undefined &&
          this.tempContract.livelo_points_rule != "" &&
          this.tempContract.livelo_amount != undefined &&
          this.tempContract.livelo_amount != "" &&
          this.tempContract.livelo_transaction_date != ""
        )
          disableButton = false;

      return disableButton;
    },
    getTheRule(rule) {
      let selected = this.liveloRange.filter((points) => {
          return points.rule == rule;
        }),
        theName = "";

      if (selected.length > 0) theName = selected[0].name;

      return theName;
    },
    getLiveloRules() {
      this.$http
        .get(
          `/livelo-points-rule/list?page=1&per_page=200&order-by=rule&order=ascend`
        )
        .then(({ data }) => {
          this.liveloRange = data.data;
        });
    },
    onChangeLiveloPointsRule(rule) {
      let total = parseInt(this.calcTotalParticipatingProgramsValue()),
        selected = this.liveloRange.filter((points) => {
          return points.rule == rule;
        });

      setTimeout(() => {
        let thePointsFee = selected[0].value
            ? selected[0].value.replace(",", ".")
            : 0,
          thePointsTotalFee =
            parseInt(this.tempContract.livelo_amount_to_receive) * thePointsFee;

        this.tempContract.livelo_points_fee = thePointsFee;
        this.tempContract.livelo_points_total_fee = thePointsTotalFee;

        this.productId = selected[0].product_code;

        this.tempContract.livelo_haya_product_id = selected[0].product_code;
        this.pointsTotalFee = thePointsTotalFee;
      }, 100);

      this.tempContract.livelo_amount_to_receive = parseInt(rule * total);

      this.form.setFieldsValue({
        livelo_amount: parseInt(total),
        livelo_amount_to_receive: parseInt(rule * total),
      });
    },
    sendAmountToLivelo(token) {
      let liveloProductionAPI = `https://apis.pontoslivelo.com.br/api/loyalty/v1/customers/${this.tempContract.livelo_customer_id}/accruals`,
        // https://apis-uat.pontoslivelo.com.br/api/oauth2/v1/customers/09164246698/accruals

        liveloRequest = {
          date: this.tempContract.livelo_transaction_date, // 2016-12-14T14:00:00.00
          partnerCode: "VJR",
          pointType: "COA_VJR",
          siteCode: "VJR_LOC",
          paymentProduct: "VJR_DEFAULT",
          transactionId: this.transactionId,
          amount: parseInt(this.tempContract.livelo_amount),
        };

      if (this.productId) liveloRequest.productId = this.productId;

      axios
        .post(liveloProductionAPI, liveloRequest, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then(({ data }) => {
          this.loadingSendPointsToLivelo = false;
          this.liveloTransactionId = data.transactionId;

          this.$message.success(
            "Transação criada com sucesso!" + data.transactionId
          );

          this.tempContract.livelo_points_is_sent = "Finalizado";
          this.tempContract.livelo_transaction_id = data.transactionId;
          this.tempContract.livelo_haya_transaction_id = this.transactionId;
          this.tempContract.livelo_haya_product_id = this.productId;

          let theTransactionHour = new Date();
          theTransactionHour.setHours(theTransactionHour.getHours() - 3);

          this.tempContract.livelo_transaction_generation_date =
            theTransactionHour;

          this.tempContract.livelo_transaction_user_id =
            this.$store.state.userData.id;

          this.$http.post("/log/create", {
            user_id: this.$store.state.userData.id,
            module_id: this.tempContract.id,
            module: "contract",
            action: "create-livelo-transaction",
            description: `${this.$store.state.userData.first_name} ${
              this.$store.state.userData.last_name
            } criou uma transação Livelo (${data.transactionId}) ${
              this.productId ? "para o produto ID (" + this.productId + ")" : ""
            }  no contrato ${this.tempContract.id}.`,
          });

          this.tempContract.modified_by = {
            name: `${this.$store.state.userData.first_name} ${this.$store.state.userData.last_name}`,
            id: this.$store.state.userData.id,
          };

          this.$http.post("/contract/update", this.tempContract).then(() => {
            this.$message.success("Dados Livelo atualizados no contrato!");
          });
        })
        .catch(({ response }) => {
          this.$message.warning(response.data.errorMessage);
          this.loadingSendPointsToLivelo = false;
        });
    },
    getLiveloToken() {
      this.loadingSendPointsToLivelo = true;
      // https://apis-uat.pontoslivelo.com.br/api/oauth2/v1/customers/09164246698/accruals
      axios
        .post(
          "https://apis.pontoslivelo.com.br/api/oauth2/v1/token",
          qs.stringify({
            grant_type: "client_credentials",
            scope: "loyalty.customer.accrual.write",
          }),
          {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
              Authorization: "Basic VkpSSTpZZ3JmWEhxdEM1S0pLZTdEQ21XNA==",
            },
          }
        )
        .then(({ data }) => {
          this.theLiveloToken = data;

          this.tempContract.livelo_token_expires_in = addSeconds(
            new Date(),
            data.expires_in
          );
          this.tempContract.livelo_token = data.access_token;
          this.sendAmountToLivelo(data.access_token);
        })
        .catch(({ response }) => {
          this.$message.warning(response.data.errorMessage);
          this.loadingSendPointsToLivelo = false;
        });
    },
  },
};
</script>

<style lang="sass" scoped>
.travel-check-boxes
  .label
    position: relative
    display: block
    z-index: 4
    font-size: 10px !important
    font-weight: 500
    color: #aaa
    transition: 0.3s
    line-height: 1
    overflow: hidden
    text-overflow: ellipsis
    width: 100%
    white-space: nowrap
  label
    display: block
    padding: 0 !important
    margin: 0 0 10px !important
    font-weight: 500
    font-size: 13px

.livelo-tab
  .resume
    font-size: 12px
    color: #000
    padding: 14px 20px !important
    border: 1px solid #ddd
    margin-bottom: 20px
    border-radius: 10px
  h2
    font-size: 14px
    font-weight: 600
</style>
