<template>
  <div>
    <div class="tabs">
      <span
        v-if="marketingTypes.includes('Livelo')"
        :class="activeTab == 'Livelo' ? 'active' : ''"
        @click="activeTab = 'Livelo'"
      >
        <a-tooltip placement="top" title="Livelo">
          <img
            src="@/assets/images/dashboard/contracts/livelo-color.png"
            height="26"
          />
        </a-tooltip>
      </span>
    </div>

    <div class="tabs-content">
      <div class="pd-20 a-center" v-if="marketingTypes.length == 0">
        Nenhum tipo de marketing selecionado.
      </div>

      <div v-if="activeTab == 'Livelo'">
        <LiveloTab
          :tempContract="tempContract"
          :form="form"
          :blockFields="true"
        />
      </div>
    </div>
  </div>
</template>

<script>
import formatThings from "@/mixins/general/formatThings.js";
import LiveloTab from "./LiveloTab.vue";

export default {
  name: "ContractMarketingDetails",
  components: {
    LiveloTab,
  },
  props: {
    tempContract: Object,
    form: Object,
  },
  mixins: [formatThings],
  data() {
    return {
      activeTab: 0,
      marketingTypes: [],
    };
  },
  mounted() {
    this.marketingTypes = this.tempContract.marketing_types
      ? JSON.parse(this.tempContract.marketing_types)
      : [];

    this.activeTab = this.marketingTypes[0];
  },
};
</script>

<style lang="sass" scoped>
//2294
.tabs
  span
    background: #FFF
    height: 45px
    width: 50px
    line-height: 2.2
    display: inline-block
    margin-right: 10px
    border: 1px solid #ddd
    padding: 6px
    text-align: center
    border-radius: 6px
    cursor: pointer
    img
      opacity: 0.3
  span.active
    img
      opacity: 1

.tabs-content
  background: #FFF
  padding: 10px
  border: 1px solid #ddd !important
  margin: 10px 0 10px
  border-radius: 6px
  font-size: 12px
  font-weight: 600
  padding: 10px
</style>
